* {
  color: #f0f0f0;
  font-family: "Franklin Gothic";
}

body {
  background-color: #525252;
  /* display: flex; */
}

.content {
  margin-left: 15vw;
}