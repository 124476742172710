.projects {
    border-top: 5px solid #f0f0f0;
    display: flex;
    height: 100vh;
    align-items: center;
    /* justify-content: center; */
    flex-direction: column;
    gap: 50px;
}

.project-contianer {
    width: 70vw;
    height: 80vh;
    overflow-y: scroll;
    background-color: #454545;
    border-color: black;
    border-style: solid;
    border-width: thin;
    
}

/* width */
::-webkit-scrollbar {
    width: 15px;
}
  
/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}
  
/* Handle */
::-webkit-scrollbar-thumb {
    background: #2e2e2e;
    border-radius: 10px;
}

#projects {
    font-size: 64px;
}