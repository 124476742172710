.home {
    display: flex;
    height: 100vh;
    /* align-items: center; */
    justify-content: center;
    flex-direction: column;
}

.name {
    padding-left: 50px;
    padding-bottom: 35px;
    height: 100px;
    font-size: 4rem;
}

.description {
    padding-left: 65px;
    font-size: 2rem;
}